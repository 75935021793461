import React from "react";
import banner from "../assets/images/banner_png-removebg-preview.png";
import { AiOutlineArrowDown } from "react-icons/ai";

const BannerFold = () => {
  let currentWindowWidth = window.screen.width;
  const navigateToAboutUsFold = async () => {};

  return (
    <div
      style={{
        height: "100vh",
        width: "100%",
      }}
      className="firstFold firstFoldBgClass"
    >
      <div className="bannerFoldSecondDiv">
        <div className="bannerFoldThirdDiv">
          {currentWindowWidth < 900 ? (
            <div className="bannerFoldImageDiv">
              <img
                src={banner}
                alt="banner"
                className="bannerFoldImage bounce-effect"
                style={{ float: "right" }}
              />
            </div>
          ) : (
            <></>
          )}

          <div className="fade-in-banner-text bannerFoldFourthDiv">
            <span className="bannerNormalText">Welcome to,</span>
            <span className="bannerBoldText">PNG BIOTEC</span>
            <span
              className={`${
                currentWindowWidth > 500
                  ? "line-to-animate animation-typewriter"
                  : ""
              } bannerNormalText`}
            >
              We have revolutionised many products of pharmaceutical &
              medicines.
              {/* {currentWindowWidth > 500
                ? `We have revolutionised many products of pharmaceutical & medicines.`
                : `We have revolutionised many products of medicines.`} */}
            </span>
            {/* <p className="bannerNormalText">
              We are one of the leading Manufacturers and Exporters of
              premium-quality Chemicals and Medicinal Drugs.
            </p> */}
          </div>

          {currentWindowWidth > 900 ? (
            <div className="bannerFoldImageDiv">
              <img
                src={banner}
                alt="banner"
                className="bannerFoldImage bounce-effect"
                style={{ float: "right" }}
              />
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>

      {/* <div
        onClick={navigateToAboutUsFold}
        className="scrollToBottomArrow flash"
      >
        <AiOutlineArrowDown />
      </div> */}
    </div>
  );
};

export default BannerFold;
