import React from "react";
import { IoCallOutline } from "react-icons/io5";
import { AiOutlineMail } from "react-icons/ai";
import { TbWorldWww } from "react-icons/tb";
import logo from "../assets/images/png-biotec-logo.png";
import FaceBookIcon from "../assets/social-icons/FaceBook.png";
import LinkedInIcon from "../assets/social-icons/LinkedIn.png";

const Footer = () => {
  let currentWindowWidth = window.screen.width;

  return (
    <a>
      <a class="anchor" id="contact"></a>

      <div
        style={{
          backgroundColor: "#006A67",
          height:
            currentWindowWidth < 1100 && currentWindowWidth > 900
              ? "60vh"
              : currentWindowWidth < 901 && currentWindowWidth > 600
              ? "55vh"
              : currentWindowWidth < 601 && currentWindowWidth > 380
              ? "70vh"
              : currentWindowWidth < 381
              ? "80vh"
              : "55vh",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: currentWindowWidth < 900 ? "center" : "start",
        }}
      >
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            className="footerSecondDiv"
            // style={{ width: "100%", borderBottom: "1px solid grey" }}
          >
            <div className="footerTextDiv">
              <div className="footerBrandingTextDiv">
                <img
                  alt="biotec_logo"
                  className="header_logo"
                  src={logo}
                  style={{
                    height: "auto",
                    width: "100%",
                    // borderRadius: "85px",
                  }}
                />

                <p
                  className="footerContactUsText"
                  style={{ marginTop: "20px" }}
                >
                  PNG BIOTEC
                </p>
              </div>

              <div className="footerAdressTextDiv">
                <p className="footerContactUsText">Contact Us</p>
                <p className="footerAddressText">
                  Gala No. AZ, Ground Floor, Sambhav Complex, <br />
                  House No. 1401, Rahnal Village, Bhiwandi, <br />
                  Thane, Maharashtra - 421302.
                </p>

                <p className="footerAddressText">
                  <div className="iconsDiv">
                    <IoCallOutline />
                    <a className="footerContactUs" href="tel:9594952564">
                      +91 9594952564
                    </a>
                  </div>

                  <div className="iconsDiv">
                    <IoCallOutline />
                    <a className="footerContactUs" href="tel:02231776137">
                      +91 31776137
                    </a>
                  </div>
                </p>

                <p className="footerAddressText footerIconsDiv">
                  <TbWorldWww />
                  <a
                    className="footerContactUs"
                    href="https://www.pngbiotec.com/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    pngbiotec.com
                  </a>
                </p>

                <p className="footerAddressText footerIconsDiv">
                  <AiOutlineMail />
                  <a
                    className="footerContactUs"
                    href="mailto:pravinwaghela@pngbiotec"
                  >
                    pravinwaghela@pngbiotec.com
                  </a>
                </p>

                <a
                  href="https://www.facebook.com/profile.php?id=100092214745891"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src={FaceBookIcon}
                    alt="facebook"
                    width="40px"
                    height="40px"
                    // style={{ marginBottom: "10px" }}
                    className="cursor-pointer social-icons hover:scale-110"
                  />
                </a>

                <a
                  href="https://www.linkedin.com/in/png-biotec-334380273/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src={LinkedInIcon}
                    alt="linked in"
                    width="40px"
                    height="40px"
                    style={{ marginLeft: "10px" }}
                    className="cursor-pointer social-icons hover:scale-110"
                  />
                </a>

                {/* <div className="z-50 pb-4 my-7 laptop:mt-5 mobile:my-4 flex gap-5 bigPc:gap-8">
            <a href="" target="_blank" rel="noreferrer">
              <img
                src={FaceBookIcon}
                alt="contactUs"
                className="cursor-pointer social-icons hover:scale-110"
              />
            </a>

            <a href="" target="_blank" rel="noreferrer">
              <img
                src={InstagramIcon}
                alt="contactUs"
                className="cursor-pointer social-icons hover:scale-110"
              />
            </a>

            <a href="" target="_blank" rel="noreferrer">
              <img
                src={LinkedInIcon}
                alt="contactUs"
                className="cursor-pointer social-icons hover:scale-110"
              />
            </a>

            <a href="" target="_blank" rel="noreferrer">
              <img
                src={TwitterIcon}
                alt="contactUs"
                className="cursor-pointer social-icons hover:scale-110"
              />
            </a>
          </div> */}
              </div>
            </div>

            <div className="footerMapImgDiv">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3766.376749058737!2d73.02978456378857!3d19.26597576158682!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7bc492e93fe23%3A0xa4743560aeca27cf!2sSambhav%20Complex!5e0!3m2!1sen!2sin!4v1680702334731!5m2!1sen!2sin"
                className="footerMapIFrame"
                title="map"
                allowFullScreen
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
          </div>

          <p className="footerCopyrightText">copyright@pngbiotec 2023-24</p>
          {/* <p className="footerCopyrightText">
          Created with 💚 by turningpointtech.in
        </p> */}
        </div>
      </div>
    </a>
  );
};

export default Footer;
