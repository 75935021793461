import React, { useEffect, useState } from "react";
import logo from "../assets/images/png-biotec-logo.png";
import { MenuOutlined } from "@ant-design/icons";
import { Drawer } from "antd";

const Header = () => {
  let currentWindowWidth = window.screen.width;
  const [visible, setVisible] = useState(false);
  const [menuClicked, setMenuClicked] = useState(false);

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  return (
    <>
      <div
        style={{
          height: "8vh",
          width: "100%",
          backgroundColor: "#006A67",
          display: "flex",
          justifyContent: "center",
          borderBottom: "1px solid white",
          position: "fixed",
          top: 0,
          zIndex: 50,
        }}
      >
        <div
          style={{
            width: currentWindowWidth > 1800 ? "85%" : "90%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            gap: "10px",
            float: "right",
          }}
        >
          <div>
            <a href="#">
              <img
                alt="biotec_logo"
                className="header_logo"
                src={logo}
                style={{
                  height: currentWindowWidth < 500 ? "25%" : "15%",
                  width: currentWindowWidth < 500 ? "25%" : "15%",
                }}
              />
            </a>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              gap: "10px",
              width: "90%",
            }}
          >
            <p
              style={{
                display: currentWindowWidth > 900 ? "block" : "none",
                fontSize: "12px",
                textTransform: "uppercase",
                textAlign: "center",
                backgroundColor: "transparent",
                margin: "20px",
              }}
              className="contactUsBtn"
            >
              <a href="#about">About Us</a>
            </p>

            <p
              style={{
                display: currentWindowWidth > 900 ? "block" : "none",
                fontSize: "12px",
                textTransform: "uppercase",
                textAlign: "center",
                backgroundColor: "transparent",
                margin: "20px",
              }}
              className="contactUsBtn"
            >
              <a href="#medicines">Products</a>
            </p>

            <p
              style={{
                display: currentWindowWidth > 900 ? "block" : "none",
                fontSize: "12px",
                textTransform: "uppercase",
                textAlign: "center",
                backgroundColor: "transparent",
                margin: "20px",
              }}
              className="contactUsBtn"
            >
              <a href="#contact">Contact Us</a>
            </p>
          </div>

          <div className=" d-block d-lg-none">
            <MenuOutlined className="mobIcon" onClick={() => showDrawer()} />
          </div>
        </div>
      </div>

      <Drawer
        placement="right"
        className="mobileHeader"
        onClose={onClose}
        visible={visible}
      >
        <ul>
          <li
            onClick={() => {
              setVisible(false);
            }}
          >
            <a href="#about">About Us</a>
          </li>
          <li
            onClick={() => {
              setVisible(false);
            }}
          >
            <a href="#medicines">Products</a>
          </li>
          <li
            onClick={() => {
              setVisible(false);
            }}
          >
            <a href="#contact">Contact Us</a>
          </li>
        </ul>
      </Drawer>
    </>
  );
};

export default Header;
