import React, { useEffect, useRef } from "react";
import injection_med from "../assets/images/injection_med.png";
import pills from "../assets/images/whitePowderPlate.png";
// import pills from "../assets/images/pills.png";
// import Powder from "../assets/images/powder_med.png";
import Powder from "../assets/images/whitePowderBowl.png";
// import tablet from "../assets/images/blue-tablet.png";
import color_pills from "../assets/images/colorfull-pills.png";
import pills_water from "../assets/images/pills-water.png";
// import needles from "../assets/images/some-pills-needles.png";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

const MedicinesFold = ({
  slideOne,
  slideTwo,
  slideThree,
  slideFour,
  slideFive,
}) => {
  // console.log(slideOne, "slideOne");
  // console.log(slideTwo, "slideTwo");
  // console.log(slideThree, "slideThree");

  let currentWindowWidth = window.screen.width;
  const medicineFoldRef = useRef(null);
  gsap.registerPlugin(ScrollTrigger);

  useEffect(() => {
    const element = medicineFoldRef.current;
    gsap.fromTo(
      element.querySelector(".medicineFoldHeadingTextForAnimation"),
      {
        opacity: 0,
        y: -80,
      },
      {
        opacity: 1,
        y: 0,
        scrollTrigger: {
          trigger: element.querySelector(".startAnimationOfMedicineFold"),
          start: "top -100%",
          end: "+=50%",
          scrub: true,
        },
      }
    );
  }, []);

  useEffect(() => {
    const element = medicineFoldRef.current;
    gsap.fromTo(
      element.querySelector(".medicineFoldFirstCardTextForAnimation"),
      {
        opacity: 0,
        x: -200,
      },
      {
        opacity: 1,
        x: 0,
        scrollTrigger: {
          trigger: element.querySelector(".startAnimationOfMedicineFold"),
          start: "top -145%",
          end: "+=50%",
          scrub: true,
        },
      }
    );
  }, []);

  useEffect(() => {
    const element = medicineFoldRef.current;
    gsap.fromTo(
      element.querySelector(".medicineFoldFirstCardImgForAnimation"),
      {
        opacity: 0,
        x: 200,
      },
      {
        opacity: 1,
        x: 0,
        scrollTrigger: {
          trigger: element.querySelector(".startAnimationOfMedicineFold"),
          start: "top -145%",
          end: "+=50%",
          scrub: true,
        },
      }
    );
  }, []);

  useEffect(() => {
    const element = medicineFoldRef.current;
    gsap.fromTo(
      element.querySelector(".medicineFoldSecondCardTextForAnimation"),
      {
        opacity: 0,
        x: 200,
      },
      {
        opacity: 1,
        x: 0,
        scrollTrigger: {
          trigger: element.querySelector(".startAnimationOfMedicineFold"),
          start: "top -220%",
          end: "+=50%",
          scrub: true,
        },
      }
    );
  }, []);

  useEffect(() => {
    const element = medicineFoldRef.current;
    gsap.fromTo(
      element.querySelector(".medicineFoldSecondCardImgForAnimation"),
      {
        opacity: 0,
        x: -200,
      },
      {
        opacity: 1,
        x: 0,
        scrollTrigger: {
          trigger: element.querySelector(".startAnimationOfMedicineFold"),
          start: "top -220%",
          end: "+=50%",
          scrub: true,
        },
      }
    );
  }, []);

  useEffect(() => {
    const element = medicineFoldRef.current;
    gsap.fromTo(
      element.querySelector(".medicineFoldThirdCardTextForAnimation"),
      {
        opacity: 0,
        x: -200,
      },
      {
        opacity: 1,
        x: 0,
        scrollTrigger: {
          trigger: element.querySelector(".startAnimationOfMedicineFold"),
          start: "top -290%",
          end: "+=50%",
          scrub: true,
        },
      }
    );
  }, []);

  useEffect(() => {
    const element = medicineFoldRef.current;
    gsap.fromTo(
      element.querySelector(".medicineFoldThirdCardImgForAnimation"),
      {
        opacity: 0,
        x: 200,
      },
      {
        opacity: 1,
        x: 0,
        scrollTrigger: {
          trigger: element.querySelector(".startAnimationOfMedicineFold"),
          start: "top -290%",
          end: "+=50%",
          scrub: true,
        },
      }
    );
  }, []);

  useEffect(() => {
    const element = medicineFoldRef.current;
    gsap.fromTo(
      element.querySelector(".medicineFoldFourthCardTextForAnimation"),
      {
        opacity: 0,
        x: 200,
      },
      {
        opacity: 1,
        x: 0,
        scrollTrigger: {
          trigger: element.querySelector(".startAnimationOfMedicineFold"),
          start: "top -360%",
          end: "+=50%",
          scrub: true,
        },
      }
    );
  }, []);

  useEffect(() => {
    const element = medicineFoldRef.current;
    gsap.fromTo(
      element.querySelector(".medicineFoldFourthCardImgForAnimation"),
      {
        opacity: 0,
        x: -200,
      },
      {
        opacity: 1,
        x: 0,
        scrollTrigger: {
          trigger: element.querySelector(".startAnimationOfMedicineFold"),
          start: "top -360%",
          end: "+=50%",
          scrub: true,
        },
      }
    );
  }, []);

  useEffect(() => {
    const element = medicineFoldRef.current;
    gsap.fromTo(
      element.querySelector(".medicineFoldFifthCardTextForAnimation"),
      {
        opacity: 0,
        x: -200,
      },
      {
        opacity: 1,
        x: 0,
        scrollTrigger: {
          trigger: element.querySelector(".startAnimationOfMedicineFold"),
          start: "top -450%",
          end: "+=50%",
          scrub: true,
        },
      }
    );
  }, []);

  useEffect(() => {
    const element = medicineFoldRef.current;
    gsap.fromTo(
      element.querySelector(".medicineFoldFifthCardImgForAnimation"),
      {
        opacity: 0,
        x: 200,
      },
      {
        opacity: 1,
        x: 0,
        scrollTrigger: {
          trigger: element.querySelector(".startAnimationOfMedicineFold"),
          start: "top -450%",
          end: "+=50%",
          scrub: true,
        },
      }
    );
  }, []);

  return (
    <a>
      <a class="anchor" id="medicines"></a>

      <section
        ref={medicineFoldRef}
        className="medicineSection startAnimationOfMedicineFold"
      >
        <div className="aboutUsFoldSecDiv container-fluid">
          <p className="aboutUsFoldHeadingText medicineFoldHeadingTextForAnimation">
            Products
          </p>

          <div className="row content-center justify-content-between align-items-center pb-4 pt-4">
            <div className="col-12 col-md-7 col-lg-6 order-2 order-md-1 medicineFoldFirstCardTextForAnimation">
              <div className="card">
                <div className="card-body">
                  {/* <h2 className="heading-underline">Header</h2> */}
                  <div className="medcineName">
                    <ul className="fa-ul">
                      {slideFour.map((items, id) => (
                        <li key={id}>
                          <span className="fa-li">
                            <i className="bi bi-check-lg me-2"></i>
                          </span>
                          <span className="content-para">{items?.name}</span>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12 col-md-5 col-lg-6 col-xxl-5 order-1 order-md-2 text-center medicineFoldFirstCardImgForAnimation">
              <img
                className="img-200 lazyloaded"
                src={pills}
                alt="pills"
                data-ll-status="loaded"
              />
              <noscript>
                <img className="img-200" src={pills} alt="pills" />
              </noscript>
            </div>
          </div>

          <div className="row content-center justify-content-between align-items-center pb-4 pt-4">
            <div className="col-12 col-md-4 col-xl-5 col-xxl-5 text-center medicineFoldSecondCardImgForAnimation">
              <img
                className="img-200 lazyloaded"
                src={Powder}
                alt="Powder"
                data-ll-status="loaded"
              />
              <noscript>
                <img className="img-200" src={Powder} alt="Powder" />
              </noscript>
            </div>

            <div className="col-12 col-md-7 col-lg-6  medicineFoldSecondCardTextForAnimation">
              <div className="card">
                <div className="card-body">
                  <div className="medcineName">
                    <ul className="fa-ul">
                      {slideFive.map((items, id) => (
                        <li key={id}>
                          <span className="fa-li">
                            <i className="bi bi-check-lg me-2"></i>
                          </span>
                          <span className="content-para">{items?.name}</span>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row content-center justify-content-between align-items-center pb-4 pt-4">
            <div className="col-12 col-md-7 col-lg-6  order-2 order-md-1 medicineFoldThirdCardTextForAnimation">
              <div className="card">
                <div className="card-body">
                  {/* <h2 className="heading-underline">Header</h2> */}
                  <div className="medcineName">
                    <ul className="fa-ul">
                      {slideThree.map((items, id) => (
                        <li key={id}>
                          <span className="fa-li">
                            <i className="bi bi-check-lg me-2"></i>
                          </span>
                          <span className="content-para">{items?.name}</span>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12 col-md-5 col-lg-6  col-xl-5 col-xxl-5 order-1 order-md-2 text-center medicineFoldThirdCardImgForAnimation">
              <img
                className="img-200 lazyloaded"
                src={injection_med}
                alt="injection_med"
                data-ll-status="loaded"
              />
              <noscript>
                <img
                  className="img-200"
                  src={injection_med}
                  alt="injection_med"
                />
              </noscript>
            </div>
          </div>

          <div className="row content-center justify-content-between align-items-center pb-4 pt-4">
            <div className="col-12 col-md-4 col-xl-5 col-xxl-5 text-center medicineFoldFourthCardImgForAnimation">
              <img
                className="img-200 lazyloaded"
                src={pills_water}
                alt="pills_water"
                data-ll-status="loaded"
              />
              <noscript>
                <img className="img-200" src={pills_water} alt="pills_water" />
              </noscript>
            </div>

            <div className="col-md-6 medicineFoldFourthCardTextForAnimation">
              <div className="card">
                <div className="card-body">
                  {/* <h2 className="heading-underline">Header</h2> */}
                  <div className="medcineName">
                    <ul className="fa-ul">
                      {slideOne.map((items, id) => (
                        <li key={id}>
                          <span className="fa-li">
                            <i className="bi bi-check-lg me-2"></i>
                          </span>
                          <span className="content-para">{items?.name}</span>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row content-center justify-content-between align-items-center pb-4 pt-4">
            <div className="col-12 col-md-7 col-lg-6  order-2 order-md-1 medicineFoldFifthCardTextForAnimation">
              <div className="card">
                <div className="card-body">
                  {/* <h2 className="heading-underline">Header</h2> */}
                  <div className="medcineName">
                    <ul className="fa-ul">
                      {slideTwo.map((items, id) => (
                        <li key={id}>
                          <span className="fa-li">
                            <i className="bi bi-check-lg me-2"></i>
                          </span>
                          <span className="content-para">{items?.name}</span>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-5 col-lg-6 col-xl-5 col-xxl-5 order-1 order-md-2 text-center medicineFoldFifthCardImgForAnimation">
              <img
                className="img-200 lazyloaded"
                src={color_pills}
                alt="color_pills"
                data-ll-status="loaded"
              />
              <noscript>
                <img className="img-200" src={color_pills} alt="color_pills" />
              </noscript>
            </div>
          </div>
        </div>
      </section>
    </a>
  );
};

export default MedicinesFold;
